import React from "react"
import { Link } from "gatsby"
import { useForm } from 'react-hook-form'

export default function Contact() {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const onSubmit = (data, e) => {
    e.target.submit()
  }

  return (
    <section className="contact" data-scroll-section>
      <form name="contact" method="post" action="/thanks" netlify-honeypot="bot-field" data-netlify="true" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />
        <div className="container">
          <div className="contact__form">
            <div className="contact__form--header">
              <h2><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></span>Contact</h2>
              <p>必要事項を入力の上、<br className="br-xs" />「送信する」ボタンを選択してください。</p>
            </div>
            <div className="contact__form--body">
              <div className="row">
                <input name="name"
                  type="text"
                  placeholder="会社名 / お名前"
                  {...register('name', { required: true })}
                />
                {errors.name && <div class="msg-box">入力してください。</div>}
              </div>
              <div className="row">
                <input name="email"
                  type="email"
                  placeholder="メールアドレス"
                  {...register('email', {
                    required: '入力してください。',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "フォーマットが正しくないです。"
                    }
                  })}
                />
                {errors.email && <div class="msg-box">{errors.email.message}</div>}
              </div>
              <div className="row">
                <textarea name="body"
                  placeholder="お問い合わせ内容"
                  {...register('body', {required: true})}
                />
                {errors.body && <div class="msg-box">入力してください。</div>}
              </div>
              <div className="row">
                <button className="submit">送信する</button>
              </div>
            </div>
          </div>
          <div className="contact__recruit">
            <Link to="https://www.wantedly.com/companies/v-force">
              <span className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg></span><span className="label">Recruit</span><span className="description">Wantedlyに掲載中</span><span className="external"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg>
              </span>
            </Link>
          </div>
        </div>
      </form>
    </section>
  );
}